<template>
  <modal  :show.sync="showBecomeSellerBuyerModal" :required="true" headerClasses="justify-content-center"
         modalClasses="modal-lg">
    <template slot="header">
      <h4 class="title title-up">{{ txt.modalTitle }}</h4>
    </template>
    <template slot="default" v-if="step1">
      <div class="text-center">
        <p-button v-on:click="selectCurrency('USD')">$ USD</p-button>
        <p-button v-on:click="selectCurrency('EUR')">€ EUR</p-button>
        <p-button v-on:click="selectCurrency('BRL')">R$ BRL</p-button>
      </div>
      <div>
        <el-table class="table-striped"
                  :data="marketplaceData.data"
                  :emptyText="$t('noaccountsfound')"
                  border>
          <el-table-column
            v-for="column in tableColumns"
            :key="column.label"
            :min-width="column.minWidth"
            :prop="column.prop"
            :label="column.label">
          </el-table-column>

          <el-table-column :label="$t('action')" width="100px">
            <div slot-scope="scope" class="text-center">
              <div class="btn-group">
                <p-button type="info" link v-on:click="selectCurrency(`${scope.row.currency}`)">
                  <i class="fa fa-pencil" style="font-size: x-large;"></i>
                </p-button>
                <p-button type="danger" link v-on:click="validateDelete(`${scope.row.currency}`)">
                  <i class="fa fa-trash" style="font-size: x-large;"></i>
                </p-button>
              </div>
            </div>
          </el-table-column>

        </el-table>
      </div>
    </template>
    <template slot="default" v-if="step2">
      <div class="row">
        <div class="col-6">
          <div class="row">
            <fg-input
              class="col-12"
              v-model="model.userid"
              :label="$t('email')"
              disabled
            >
            </fg-input>
            <fg-input
              class="col-12"
              v-model="model.phone"
              :label="$t('phonetxt')"
              :placeholder="$t('phone')"
              type="number"
            >
            </fg-input>

          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <fg-input
              class="col-6"
              v-model="model.currency"
              :label="$t('currency')"
              :placeholder="$t('currency')"
              disabled=""
              type="text"
            >
            </fg-input>
            <div class="col-6">
              <label> {{ $t('type') }} </label>
              <el-select v-model="selects.selected" placeholder="Selecione tipo"
                         v-on:change="setType">
                <el-option class="select-default"
                           v-for="item in selects.options"
                           :key="item.prop" :label="item.label" :value="item.prop">
                </el-option>
              </el-select>
            </div>
            <fg-input
              class="col-6"
              type="number"
              v-model="model.min_sale"
              :label="$t('minsale')"
            >
            </fg-input>
            <fg-input
              class="col-6"
              type="number"
              v-model="model.max_sale"
              :label="$t('maxsale')"
            >
            </fg-input>
          </div>
        </div>
        <div class="form-group col-12">
          <label>Description</label>
          <textarea v-model="model.description" rows="1" maxlength="400" class="form-control"></textarea>
        </div>
      </div>

    </template>
     <template slot="footer" v-if="step1">
       <p-button type="success"  @click="clean" link>
        {{ txt.ok }}
      </p-button>
    </template>
    <template slot="footer" v-else-if="step2">
      <p-button type="success" @click="validate" link>
        {{ txt.savechanges }}
      </p-button>
       <p-button @click="goBack" link>
        {{ txt.back }}
      </p-button>
    </template>
  </modal>
</template>

<script>
import Modal from 'src/components/UIComponents/Modal';
import {mapActions, mapState} from "vuex";
import {CheckboxGroup, Checkbox} from 'element-ui';
import Vue from "vue";
import swal from "sweetalert2";

Vue.use(CheckboxGroup);
Vue.use(Checkbox);

export default {
  name: "BecomeSellerBuyer",
  components: { Modal },
  computed: {
    ...mapState('becomeSellerBuyer', ['showBecomeSellerBuyerModal', 'user', 'marketplaceData'])
  },
  props: {},
  data() {
    return {
      txt: {
        modalTitle: this.$t('availablecurrencies'),
        savechanges: this.$t('savechanges'),
        cancel: this.$t('cancel'),
        back: this.$t('back'),
        ok: this.$t('ok')
      },
      queriedData: [],
      tableColumns: [
        {
          prop: 'currency',
          label: this.$t('currency')
        },
        {
          prop: 'min_sale',
          label: this.$t('minsale')
        },
        {
          prop: 'max_sale',
          label: this.$t('maxsale')
        },
      ],
      model: {
        userid: '',
        can_sell: false,
        can_buy: false,
        min_Sale: 0,
        max_Sale: 0,
        currency: false,
        exchange: 0,
        description: '',
        phone: '',
      },
      selects: {
        selected: '',
        options: [
          {
            label: 'Only Sell',
            prop: 'sell'
          },
          {
            label: 'Only Buy',
            prop: 'buy'
          },
          {
            label: 'Both',
            prop: 'both'
          }
        ]
      },
      step1: true,
      step2: false,
      step3: false,
      isNew: false,
    }
  },
  methods: {
    ...mapActions('becomeSellerBuyer', ['closeBecomeSellerBuyerModal','clean', 'setMarketplaceData']),
    validate() {
      if (!this.model.phone || !this.model.description || !this.model.min_sale || !this.model.max_sale
        || (!this.model.can_buy && !this.model.can_sell)) {
        swal(this.$t('attentiontxt'), this.$t('emptyfields'), "warning")
      } else {
        let success = resp => {
          swal(this.$t("successtxt"), this.$t(resp.msg), "success").then(this.goBack)
        }
        let fail = resp => {
          console.log(resp)
        }

        this.$postMarketEntry_V2(this.model, this.isNew).then(success, fail)
      }
    },
    goBack() {
      this.step1 = true
      this.step2 = false
      this.reloadEntries()
    },
    reloadEntries() {
      let success = resp => {
        this.setMarketplaceData(resp)
      }
      let fail = resp => {
        console.log(resp)
      }

      this.$getMarketEntries_V2(this.user).then(success, fail)
    },
    setType() {
      if ( this.selects.selected == 'sell') {
        this.model.can_sell = true
        this.model.can_buy = false
      } else if (this.selects.selected == 'buy') {
        this.model.can_sell = false
        this.model.can_buy = true
      } else if (this.selects.selected == 'both') {
        this.model.can_sell = true
        this.model.can_buy = true
      }
    },
    setTypefromEdit() {
      if (this.model.can_sell && this.model.can_buy) {
        this.selects.selected = 'both'
      } else if (this.model.can_sell && !this.model.can_buy) {
        this.selects.selected = 'sell'
      } else if (!this.model.can_sell && this.model.can_buy) {
        this.selects.selected = 'buy'
      }
    },
    selectCurrency(value) {
      let editForm = this.marketplaceData.data.filter( a => a.currency === value )

      if(editForm.length == 0) {
        this.model = {
          userid: this.user,
          can_sell: false,
          can_buy: false,
          min_sale: 0,
          max_sale: 0,
          currency: value,
          exchange: 0,
          description: '',
          phone: ''
        }
        this.isNew = true
      } else {
        this.isNew = false
        this.model = Object.assign({}, editForm[0])
        this.setTypefromEdit()
      }
      this.step1 = false
      this.step2 = true
    },
    validateDelete(row) {
      let array = this.marketplaceData.data
      let obj = array.filter( e => e.currency === row)
      swal({
        title: this.$t('attentiontxt'),
        text: this.$t('confirmdelentry'),
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
      }).then(
        value => {
          this.deleteRecord(obj)
        },
        dismiss => {

        }
        ).catch(swal.noop);
    },
    deleteRecord(obj) {
      let param = {
        'userid':  obj[0].userid,
        'currency': obj[0].currency
      }
      let success = resp => {
        swal(this.$t("successtxt"), this.$t(resp.msg) , "success").then(this.goBack)
      }
      let fail = resp => {
        swal(this.$t("attentiontxt"), this.$t('rdpdownmsg') , "warning").then(this.goBack)
      }

      this.$delMarketEntry(param).then(success, fail)
    },
  },
  watch: {
    showBecomeSellerBuyerModal(val) {

    }
  }
}
</script>

<style scoped>

</style>
